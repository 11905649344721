@import "../../../assets/scss/mixins";
@import "../../../assets/scss/variables";

%styles {
    @include fluid(font-size, 18px, 24px);
    @include fluid(line-height, 24px, 32px);
    @include fluid(margin-bottom, 20px, 30px);
    @include fluid(margin-top, 30px, 50px);
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
}
.title {
    @extend %styles;
    margin: 0;
    text-align: center;
}
.title_left {
    @extend %styles;
}

.editRow {
    margin-bottom: 30px;
}

.Row {
    margin-bottom: 50px;
}

.noMargin {
    margin: 90px -15px 0;
}

.form {
    margin: 0 -15px;
}

.colRow {
    padding: 0 !important;
}

.flexCol {
    display: flex;
    flex-direction: column;
    height: auto;
}
.tableAlignRight {
    tr > th,
    td {
        &:last-child {
            text-align: right;
        }
    }
}
:global {
    .ant-table-footer {
        background-color: #ccdbea;
        display: flex;
        justify-content: space-between;
        div {
            @include fluid(font-size, 10px, 14px);
            @include fluid(line-height, 14px, 17px);
            &:last-child {
                font-weight: 500;
                @include fluid(font-size, 12px, 16px);
                @include fluid(line-height, 16px, 19px);
            }
        }
    }
}

.textarea {
    height: 100%;

    div {
        height: 100%;
        &:global(.ant-form-item-control-input) ~ div {
            height: auto;
        }
    }
    div:last-child {
    }
}
.textarea100 {
    height: 100% !important;
}

.textarea150px {
    height: 150px !important;
}

.item {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    &:not(:last-child) {
        margin-bottom: 30px;
    }
    &__100 {
        height: 100%;
    }
}

.span {
    display: block;
    padding: 10px;
    @include fluid(font-size, 12px, 14px);
    line-height: 1.2;
    color: $dark-blue;
}

.rowInCol {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

@import "src/assets/scss/mixins";

.collapse {
    &_even {
        .table_tbody_row {
            background-color: #fff;

            &:nth-child(even) {
                background-color: #f5fcfe;
            }
        }
    }

    &_odd {
        .table_tbody_row {
            background-color: #f5fcfe;

            &:nth-child(even) {
                background-color: #fff;
            }
        }
    }

    &:nth-child(even) {
        :global {
            .ant-collapse-header {
                background-color: #fff !important;
            }
        }
    }

    &:nth-child(odd) {
        :global {
            .ant-collapse-header {
                background-color: #f5fcfe !important;
            }
        }
    }

    &_panel {
        position: relative;
        z-index: 10;
    }

    &_mask {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        visibility: hidden;
        opacity: 0;
        background-color: rgba(#fff, 0.8);

        transition: visibility 0.3s ease-in-out, z-index 0.3s ease-in-out, opacity 0.3s ease-in-out;

        &_loader {
            position: sticky;
            width: fit-content;

            transform: translate(-50%);
        }

        &_active {
            visibility: visible;
            z-index: 9;
            opacity: 1;
        }
    }

    :global {
        .ant-collapse-content {
            position: relative;

            &-box {
                padding: 0 !important;
                background-color: #fff;
            }
        }

        .ant-collapse-item {
            border-bottom: none;

            &:nth-child(even) {
                .ant-collapse-header {
                    background-color: #fff;
                }
            }

            &:nth-child(odd) {
                .ant-collapse-header {
                    background-color: #f5fcfe;
                }
            }
        }

        .avt-collapse-content {
            display: table;
            vertical-align: middle;
            border-color: inherit;
        }

        .ant-collapse-header {
            flex-direction: row-reverse;
            justify-content: flex-end;

            &-text {
                color: #231f20;
            }

            .ant-collapse-arrow {
                margin-right: 0 !important;
            }
        }

        .ant-collapse-expand-icon {
            width: 22px;
            height: 22px;
            display: flex;
            justify-content: center;
            align-items: center;

            .anticon-loading {
                margin: 0 !important;
                font-size: 18px !important;
            }
        }

        .ant-collapse-item-disabled {
            .ant-collapse-header {
                cursor: auto;
            }
        }

        .ant-empty {
            padding: 20px 0;
            position: sticky;
            left: 0;
            margin-left: 0;
            margin-right: 0;
        }
    }

    .text {
        width: fit-content;
        position: sticky;
        left: 15px;
        display: flex;
        gap: 40px;
    }

    .expand_icon {
        width: 22px;
        height: 22px;
        display: flex;
        justify-content: center;
        align-items: center;

        .anticon-loading {
            margin: 0 !important;
            font-size: 18px !important;
        }
    }
}

.wrapper {
    width: 100%;
    overflow-x: auto;
    display: flex;
    flex-direction: column;

    &_table {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
}

.table {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    :global {
        .ant-empty {
            padding: 32px 0;
            margin: 0;
            position: sticky;
            left: 0;
        }
    }

    &_onlyHeader {
        :global {
            .ant-table-tbody {
                .ant-table-cell {
                    padding: 0;
                    line-height: 0;
                    opacity: 0;
                }
            }
        }
    }

    &_onlyBody {
        :global {
            .ant-table-thead {
                display: none;
            }
        }
    }

    &_thead {
        display: flex;
        align-items: flex-start;
        gap: 15px;
        background-color: #fff;
        position: sticky;
        top: 0;
        z-index: 12;
        border-bottom: 1px solid #f5fcfe;

        &_cell {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-weight: 700;
            @include fluid(font-size, 10px, 14px);
            cursor: pointer;
            transition: 0.3s color;
            &:hover {
                color: #00b3ee;
            }
            &_arrows {
                display: flex;
                flex-direction: column;
                &_item {
                    margin: -2.5px 0 -2.5px;
                    &_active {
                        color: #00b3ee;
                    }
                }
            }
        }
    }

    &_tbody {
        position: relative;
        flex-grow: 1;

        &_row {
            display: flex;
            align-items: flex-start;
            gap: 15px;
        }

        &_mask {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            background-color: rgba(#fff, 0.8);
            visibility: hidden;
            opacity: 0;

            transition: visibility 0.3s ease-in-out, z-index 0.3s ease-in-out, opacity 0.3s ease-in-out;

            &_loader {
                position: sticky;
                width: fit-content;
                height: fit-content;
            }

            &_active {
                visibility: visible;
                z-index: 1000;
                opacity: 1;
            }
        }
    }

    &_cell {
        padding: 15px 0;

        &:last-child {
            padding-right: 15px;
        }

        &:first-child {
            padding-left: 15px;
        }
    }
}

.virtualList {
    min-width: 100%;

    :global {
        .ReactVirtualized__Grid__innerScrollContainer {
            min-width: 100%;
        }
    }
}

.modal{
  :global(.ant-modal-footer){
    display: flex;
  }
  :global(.ant-row){
    margin: 0 0 10px 0 !important;

  }
}

.text {
  font-size: 20px;
  font-weight: bold;
}
.nonExistent {
  width: 100%;
   > div {
    display: flex;
    justify-content: space-between;
  }
}
@import "../../assets/scss/mixins";
@import "../../assets/scss/variables";

.editById {
  transition: all 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    color: $secondary;
  }
}

.actions {
  display: flex;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: 30px;
  }
}

.searchBtn {
  margin-left: 0;

  :global {
    .ant-btn {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

.wrap {
  width: fit-content !important;

  :global(.ant-modal-footer) {
    display: flex;
    justify-content: center;
    flex-direction: row-reverse;
    @include fluid(column-gap, 12px, 30px);
    :global(.ant-btn){
      max-width: 360px;
    }
  }

}
@import "../../assets/scss/mixins";
@import "../../assets/scss/variables";

.content {
    @include fluid(padding-left, 25px, 40px);
    @include fluid(padding-right, 25px, 40px);
    @include fluid(padding-bottom, 20px, 30px);
    @include fluid(padding-top, 120px, 130px);
    display: flex;
    flex-direction: column;
}

.layout {
    min-height: 100vh;

    &_wrapper {
        flex-grow: 1;
        @include fluid(padding, 20px, 30px);
        background: #fff;
        border-radius: 10px;
        width: 100%;

        &:not(:last-child) {
            @include fluid(margin-bottom, 20px, 30px);
        }
    }
}

.overflow > * {
    overflow-y: auto;
}

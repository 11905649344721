@import "../../assets/scss/variables";

%wrap {
    background: #f5f6f6;
    border-radius: 5px;
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    overflow: hidden;
    input {
        cursor: pointer;
        position: absolute;
        width: 100%;
        opacity: 0;
        left: 0;
        top: 0;
        bottom: 0;
        z-index: 2;
    }
}

.uploaderWrap {
    @extend %wrap;
}

.reset {
    position: relative;
    z-index: 5;
    &__file {
        width: 160px;
    }
}

.uploaderWrap__large {
    @extend %wrap;
    width: 100%;
    height: 260px;
}
.uploaderWrap__emp {
    @extend %wrap;
    color: $white;
    justify-content: flex-start;
    background-color: $white;
}

.uploaderPlus {
    svg path {
        fill: black;
    }
}
.avatar {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;    
    object-fit: contain;
    object-position: center;
}

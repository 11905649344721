.table {
    :global {
        .ant-table-body {
            overflow-y: auto !important;
        }

        .ant-pagination {
            margin-bottom: 0;
        }
    }
}

.form {
  //   max-width: 904px;
  width: 100%;

  &__vertical {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
}

.rightBtn {
    &:not(:last-child) {
        margin-bottom: 30px;
    }
}

@import "../../../assets/scss/mixins";
@import "../../../assets/scss/variables";
.row {
    @include fluid(margin-bottom, 45px, 70px);
    &:last-child {
        margin-bottom: 0;
    }
}
.quantity {
    @include fluid(font-size, 18px, 20px);
    @include fluid(line-height, 20px, 24px);
    font-weight: 600;
    color: $primary;
    &_value {
        @include fluid(font-size, 26px, 32px);
        @include fluid(line-height, 26px, 32px);
        @include fluid(margin-left, 12px, 15px);
        font-weight: 600;
        color: $dark-blue;
    }
}
.tableHead {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    &_title {
        @include fluid(font-size, 18px, 24px);
        @include fluid(line-height, 24px, 32px);
        font-family: "Inter";
        font-weight: 600;
        color: #000000;
    }
}

.lessonInfo {
    display: flex;
    align-items: center;
    height: 40px;
    color: $dark-blue;
}

.labelMargin {
    margin-bottom: 10px;
}

.video_block {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    border-radius: 10px;
    border: 1px solid $light-blue;
    @include fluid(padding, 20px, 30px);
    &__close {
        position: absolute;
        @include fluid(right, 10px, 20px);
        @include fluid(top, 10px, 20px);
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        margin: 0;        
        border: none;
        padding: 0;
        cursor: pointer;
        z-index: 5;
    }
    &__show {
        display: flex;
        align-items: center;
        width: 100%;
    }
    &__checkbox {
        margin: 0 15px 0 0;
    }
    &__span {
        @include fluid(font-size, 14px, 18px);
        line-height: 1.2;
        color: $black;
    }
    &__block {
        width: 100%;
        margin: 0;
    }
}

.buttonAddContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 60px;
    padding-bottom: 60px;
}

.buttonAdd {
    margin: 0;
}

.videoSection {
    @include fluid(margin-top, 30px, 50px);
}

.rowVideo {
    margin-top: 8px;
}

.imagesSection {
    @include fluid(margin-top, 30px, 50px);
}

.imagesUpload {
    margin-top: 8px;
}
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/variables";
.row {
    @include fluid(margin-bottom, 45px, 70px);
    &:last-child {
        margin-bottom: 0;
    }
}
.quantity {
    display: flex; 
    align-items: flex-end;
    &__label {
        @include fluid(font-size, 18px, 20px);
        @include fluid(line-height, 20px, 24px);
        font-weight: 600;
        color: $primary;
    }
    &__value {
        @include fluid(font-size, 26px, 32px);
        @include fluid(line-height, 26px, 32px);
        @include fluid(margin-left, 12px, 15px);
        font-weight: 600;
        color: $dark-blue;
    }
}

.tableHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    &__title {
        @include fluid(font-size, 18px, 24px);
        line-height: 1.3;
        font-weight: 600;
        color: $black;
    }
    & > *:last-child {
        margin: 0 !important;
    }
}

@import "../../assets/scss/mixins";

.login {
  background-color: #f5f6f6;
  padding: 0;
  height: 100vh;
  position: relative;
  &__form {
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    &_block {
      @include fluid(width, 280px, 342px);
      @include fluid(padding, 20px, 30px);
      text-align: center;
      background-color: #fff;
      box-shadow: 0 0 16px rgba(132, 132, 132, 0.25);
    }
    &_title {
      @include fluid(font-size, 20px, 24px);
      @include fluid(line-height, 26px, 32px);
      @include fluid(margin-bottom, 20px, 30px);
      font-family: "Inter", sans-serif;
      font-weight: 600;
      color: #231f20;
    }
    &_input {
      @include fluid(margin-bottom, 15px, 20px);
    }
    &_button {
      @include fluid(margin-top, 35px, 50px);
    }
  }
}


.label {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 10px;
}

.item {
  height: 100%;

  & div {
    height: 100%;
  }
}

.img {

}

:global {
  .ant-spin {
    height: 100%;
  }

  .anticon-spin {
    font-size: 20px;
  }
}

.quillClass {
  height: 295px !important;

  div {
    height: auto;
  }

  div:last-child {
    height: calc(100% - 76px);
  }

}
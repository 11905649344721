@import "../../../assets/scss/mixins";
@import "../../../assets/scss/variables";

.rowMargin50 {
    margin-bottom: 50px;
}

.customMargin {
    margin-left: 0;
    margin-right: 0;
}

.mx0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.notLastMargin {
    &:not(:last-child) {
        margin-bottom: 30px;
    }
}

.labelMargin {
    margin-bottom: 10px;
}

.lessonInfo {
    display: flex;
    align-items: center;
    color: $dark-blue;
}

.textAreaCount {
    font-size: 12px;
    color: $dark-blue;
    text-align: right;
    padding-right: 10px;
    margin-top: 5px;
}

.flexAddress {
    display: flex;
    align-items: flex-end;
    column-gap: 15px;

    & > div {
        &:nth-child(1) {
            flex-grow: 1;
        }

        &:nth-child(2) {
            margin-bottom: -1px;
        }
    }
}

.buttonIcon:disabled {
    border: none;
    background-color: #8ea3b8;
}

.pl0 {
    padding-left: 0 !important;
}

.pr0 {
    padding-right: 0 !important;
}

.tableHead {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    &_title {
        @include fluid(font-size, 18px, 24px);
        @include fluid(line-height, 24px, 32px);
        font-family: "Inter";
        font-weight: 600;
        color: #000000;
    }
}

.description {
    padding: 10px;
    border-radius: 5px;
    border: 2px solid $secondary;

    overflow: auto;
    max-height: 500px;

    img {
        width: 100%;
    }
}

.df {
    display: flex;
    align-items: center;
    justify-content: center;
}

@import "../../assets/scss/mixins";
@import "../../assets/scss/variables";

.button {
    margin: 0;

    &_bn {
        :global {
            .ant-btn-default {
                border: none;
                background-color: transparent !important;
                color: #1c4771 !important;
            }
        }

        &:hover {
            :global {
                .ant-btn-default {
                    color: #00b3ee !important;
                }
            }

            svg path {
                fill: #00b3ee;
            }
        }
    }

    svg {
        margin-right: 10px;
    }
}

.green {
    td {
        background: #f0fce6 !important;
    }
}

.red {
    td {
        background: #fce7ee !important;
    }
}

.btns {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 30px !important;

    &__item {
        font-size: 14px;
        line-height: 1.2;
        margin: 0 !important;
    }
}

.modal {
    padding: 0;
    border-radius: 10px;
    width: 100% !important;
    @include fluid(max-width, 700px, 900px);
    box-shadow: 0 0 16px rgba(132, 132, 132, 0.25);

    :global {
        .ant-modal-body {
            @include fluid(max-height, 500px, 700px);
            overflow: auto;
        }

        .ant-table-cell {
            &:last-child {
                padding-top: 0;
                padding-bottom: 0;
            }
        }

        .ant-checkbox-indeterminate .ant-checkbox-inner:after {
            content: none;
        }

        .ant-table-tbody > tr.ant-table-row-selected > td {
            background: none;
        }
    }
}

.searchBtn {
    margin: 0;

    :global {
        .ant-btn {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
}

.actions {
    display: flex;
    align-items: center;

    & > *:not(:last-child) {
        margin-right: 30px;
    }
}

.topWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 20px;

    &:not(:last-child) {
        padding-bottom: 20px;
    }
}

.excelModal {
    :global {
        .ant-modal-footer {
            display: none;
        }
    }

    &_space {
        width: 100%;
        justify-content: center;

        :global {
            .ant-space-item {
                width: fit-content;
            }
        }
    }
}

.sortTable {
    :global {
        .ant-checkbox-indeterminate .ant-checkbox-inner:after {
            width: 10px;
            height: 10px;
            left: 50%;
            background-color: #1c4771;
        }
    }
}

.btn {
    width: 100px;
    margin-left: 15px;
}

.inp {
}

.adding {
    width: 100%;
    padding: 5px 10px;
}

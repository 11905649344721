.deleteButton {
    position: relative;
    bottom: 10px;
}

.table {
    margin-bottom: 20px;

    &__head {
        margin-right: 8px;
        margin-bottom: 10px;
        left: 0;
        position: relative;
    }
    &__body {
        max-height: 440px;
        overflow-y: scroll;
        & > div {
            &:nth-child(odd) {
                background-color: #f5fcfe;
            }
        }
    }
    &__elem {
        padding: 10px;
        margin: 0 !important;
        &:last-child {
            text-align: right;
            div {
                justify-content: end;
            }

            span {
                text-align: right;
            }
            input {
                text-align: right;
            }
        }
    }
    &__footer {
        padding: 10px;
        background-color: #ccdbea;
        display: flex;
        justify-content: space-between;
        div {
            padding: 10px;
            font-size: 14px;
            line-height: 16px;
        }
    }
    &__row {
        &_green {
            background-color: rgba(131, 196, 71, 0.79);
            :global(.ant-select) {
                background: transparent !important;
            }
            input {
                background: transparent !important;
            }
        }
        &_red {
            :global(input) {
                background: transparent !important;
            }
            input {
                background: transparent !important;
            }
            background-color: rgba(222, 17, 84, 0.73);
        }
    }
    &__rowElem {
        flex-direction: row;
        align-items: center;
    }
}
.item {
    margin: 0;
    width: 100%;

    :global(.ant-select-selector) {
        background: transparent !important;
    }
    input {
        background: transparent !important;
        opacity: 1 !important;
        width: 95%;
    }
}

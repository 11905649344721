@import "../../../assets/scss/mixins";
@import "../../../assets/scss/variables";
.row {
  @include fluid(margin-bottom, 45px, 70px);
  &:last-child {
    margin-bottom: 0;
  }
}
.quantity {
  @include fluid(font-size, 18px, 20px);
  @include fluid(line-height, 20px, 24px);
  font-weight: 600;
  color: $primary;
  &_value {
    @include fluid(font-size, 26px, 32px);
    @include fluid(line-height, 26px, 32px);
    @include fluid(margin-left, 12px, 15px);
    font-weight: 600;
    color: $dark-blue;
  }
}
.tableHead {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  &_title {
    @include fluid(font-size, 18px, 24px);
    @include fluid(line-height, 24px, 32px);
    font-family: "Inter";
    font-weight: 600;
    color: #000000;
  }
}

.main {
    display: flex;
    flex-direction: column;
    row-gap: 50px;
}

.labelMargin {
    margin-bottom: 10px;
}

.lessonInfo {
    display: flex;
    align-items: center;
    height: 40px;
    color: $dark-blue;
}

.rowMargin {
    margin-bottom: 50px;
}

.titleH4 {
    font-weight: 600;
    font-size: 32px;
    line-height: 44px;
    color: $black;
    margin: 0 0 32px;
}

.list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 40px;
    column-gap: 40px;
}

.Item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  @include fluid(padding, 20px, 30px);
  @include fluid(border-radius, 10px, 15px);
  border: 1px solid $grey;
  &_close {
    cursor: pointer;
    margin-left: auto;
    margin-bottom: 5px;
    border: none;
    padding: 0;
  }
  &_desc {
    display: flex;
    flex-wrap: wrap;
    row-gap: 30px;
    margin-bottom: 30px;
  }
  &_descItem {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    & > * {
        width: 100%;
    }
    & > span {
      &:nth-child(1) {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: $black;
        margin-bottom: 10px;
      }
      &:nth-child(2) {
        display: flex;
        align-items: center;
        height: 40px;
      }
    }
  }
  &_questions {
    margin-bottom: 30px;
  }
  &_questionsTitle {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: $black;
    margin-bottom: 30px;
  }
  &_questionsList {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 30px;
  }
  &_questionsItem {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    column-gap: 15px;
    width: 100%;
    & > div:nth-child(1) {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      & > span {
        &:nth-child(1) {
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
          color: $black;
          margin-bottom: 10px;
        }
        &:nth-child(2) {
          display: flex;
          align-items: center;
          height: 40px;
        }
      }
    }
  }
  &_questionsItemChekbox {
    margin-bottom: 5px;
  }
  &_questionsItemButton {
    padding-top: 9px;
    padding-bottom: 9px;
  }
}


.answerDelete {
    display: flex;
    align-items: center;
    & > div {
        margin: 0 10px 0 0;
    }
}

@import "../../assets/scss/variables";
@import "../../assets/scss/mixins";
.commonIconStyles {
  line-height: 0;
  cursor: pointer;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    @include fluid(height, 16px, 24px);
    path {
      transition: all 0.3s ease-in-out;
    }
  }
  &:hover {
    svg path {
      fill: $secondary;
    }
  }

  &_dark {
    svg path {
      fill: $dark-blue;

      &:hover {
        fill: $secondary;
      }
    }
  }
}

$black: #000;
$primary: #231F20;
$secondary: #00B3EE;
$dark-blue: #1C4771;
$light-blue: #8EA3B8;
$grey: #BCBDC0;
$dark-gray: #80838A;
$light-grey: #f0f0f0;
$white: #ffffff;
$yellow: #E9BB18;
$green: #83C447;
$light-red: #fba8af;
$red: #DE1154;
$error: #DE1111;
@import "../../assets/scss/mixins";
@import "../../assets/scss/variables";

.head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include fluid(margin-bottom, 15px, 30px);

    &__tabs {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
    }

    &__btn {
        width: auto;
        padding: 10px 15px;
        @include fluid(font-size, 12px, 14px);
        line-height: 1.2;

        &_active {
            background-color: $secondary;
            border-color: $secondary;
        }
    }

    &__add {
        margin: 0;
    }
}

.catalogs {
    margin-bottom: 30px;

    &__item {
        &:not(:last-child) {
            @include fluid(padding-bottom, 10px, 20px);
            @include fluid(margin-bottom, 10px, 20px);
            border-bottom: 1px solid #969696;
        }
    }
}

.item {
    display: flex;

    &__img {
        width: 20%;
        height: 150px;
        object-fit: cover;
        object-position: center;
    }

    &__desc {
        display: flex;
        flex-direction: column;
        height: auto;
        width: 80%;
        padding-left: 20px;

        & > h3 {
            font-weight: 600;
            @include fluid(font-size, 16px, 20px);
            line-height: 1.2;
            color: $primary;
            margin: 0 0 20px;
        }

        :global {
            .ant-typography {
                width: 90%;
                font-weight: 500;
                @include fluid(font-size, 12px, 14px);
                line-height: 1.2;
                color: $black;
                margin: 0 0 20px;
                display: block;
            }

            .ant-typography-expand {
                display: none;
            }
        }
    }

    &__links {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: auto;

        &_block {
            & > a {
                display: inline-block;
                font-weight: 500;
                font-size: 12px;
                line-height: 1.25;
                color: $dark-blue;
                padding: 12px 24px;
                transition: 0.3s color;

                &:hover {
                    color: $secondary;
                }

                &:not(:last-child) {
                    margin-right: 20px;
                }
            }
        }
    }

    :global {
        .ant-spin {
            width: 20%;
            height: 150px;
        }
    }
}

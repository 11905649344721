@import "../../../assets/scss/mixins";
@import "../../../assets/scss/variables";
.row {
    @include fluid(margin-bottom, 45px, 70px);
    &:last-child {
        margin-bottom: 0;
    }
}

.quantity {
    display: flex;
    align-items: flex-end;
    &__label {
        @include fluid(font-size, 18px, 20px);
        @include fluid(line-height, 20px, 24px);
        font-weight: 600;
        color: $primary;
    }
    &__value {
        @include fluid(font-size, 26px, 32px);
        @include fluid(line-height, 26px, 32px);
        @include fluid(margin-left, 12px, 15px);
        font-weight: 600;
        color: $dark-blue;
    }
}

.tableHead {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    &_title {
        @include fluid(font-size, 18px, 24px);
        @include fluid(line-height, 24px, 32px);
        font-family: "Inter";
        font-weight: 600;
        color: #000000;
    }
}

@import "../../assets/scss/mixins";
@import "../../assets/scss/variables";
// spin loader
.ant-spin {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.anticon-spin {
    @include fluid(font-size, 30px, 56px);
    color: $secondary;
}

.ant-select-arrow-loading {
    .anticon-spin {
        @include fluid(font-size, 18px, 24px);
    }
}

// Styles for checkbox
.ant-checkbox {
    &::after {
        border-color: $dark-blue;
    }

    .ant-checkbox-inner {
        width: 24px;
        height: 24px;
        border-color: $dark-blue;

        &::after {
            left: 30.5%;
        }
    }

    .ant-checkbox-input:checked + .ant-checkbox-inner {
        border-color: $dark-blue;
        background-color: $dark-blue;
    }
}

.ant-checkbox-wrapper {
    display: flex;
    align-items: center;

    & > span {
        &:not(.ant-checkbox) {
            font-size: 18px;
            line-height: 24px;
            color: $primary;
            margin: 0 0 0 15px;
            padding: 6px 0 0;
        }
    }
}

// // Button
.ant-btn {
    @include fluid(font-size, 16px, 18px);
    @include fluid(line-height, 20px, 24px);
    @include fluid(padding-left, 20px, 30px);
    @include fluid(padding-right, 20px, 30px);
    background: $dark-blue;
    border-radius: 5px;
    width: 100%;
    padding-top: 12px;
    padding-bottom: 12px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    color: #ffffff;
    border: none;
    transition: all 0.3s ease-in-out;
    height: auto;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background: $secondary;
        color: #ffffff;
    }

    .anticon-spin {
        font-size: 18px !important;
    }

    &-ghost {
        background: transparent;
        color: $dark-blue;
    }

    &-default {
        color: $dark-blue;
        background: transparent;
        border: 1px solid $dark-blue;

        &:hover {
            background: transparent;
            color: $secondary;
            border-color: $secondary;
        }
    }

    &-primary {
        border: 1px solid $dark-blue;

        &:focus {
            border-color: $secondary;
        }
    }

    &-loading {
        &:hover {
            background: $dark-blue;
            border-color: $dark-blue;
        }

        &:focus {
            background: $dark-blue;
            border-color: $dark-blue;
        }
    }

    &-dashed {
        color: $dark-blue;
        background: transparent;
        border: 1px dashed $dark-blue;

        &:hover {
            background: transparent;
            color: $secondary;
            border-color: $secondary;
        }
    }

    &-text {
        color: $dark-blue;
        background: transparent;

        &:hover {
            background: transparent;
            color: $secondary;
        }
    }
}

.ant-form-item {
    &:last-child {
        margin: 0;
    }
}

.ant-input {
    resize: none;
    border: none;
    width: 100%;
    background-color: #f5f6f6;
    outline: none;
    padding: 10px;
    // border-radius: 4px;
    &-affix-wrapper {
        border: none;
        background-color: #f5f6f6;
        padding: 10px;

        &-focused {
            border: none;
            box-shadow: none;
        }
    }

    &-suffix {
        padding-right: 10px;
    }

    &:focus {
        border: none;
        box-shadow: none;
    }

    &-search-button {
        border: none;
    }
}

.ant-form-item-control-input-content {
    border-radius: 4px;
}

//layout
.ant-layout {
    background: #f5f6f6;
    min-height: 100vh;

    &-sider {
        position: relative;
        z-index: 2;
        background-color: $secondary;

        &-children {
            width: 292px;
            position: fixed;
        }
    }
}

// modal
.ant-modal {
    &-content {
        border-radius: 20px;
    }

    &-body {
        padding: 50px 50px 0;
    }

    &-footer {
        padding: 30px 50px 50px;
        border-top: none;
    }
}

//menu
.ant-menu {
    background-color: transparent;

    &-item {
    }
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #f5f6f6;
    color: #006b8f;
}

.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
    @include fluid(padding-left, 25px, 40px);
    @include fluid(padding-right, 25px, 40px);
    @include fluid(padding-bottom, 12px, 15px);
    @include fluid(padding-top, 12px, 15px);
    @include fluid(font-size, 20px, 24px);
    @include fluid(line-height, 26px, 32px);
    background-color: transparent;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    color: #ffffff;
    height: auto;
    border-radius: 10px 0 0 10px;
}

.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark,
.ant-menu.ant-menu-dark .ant-menu-sub {
    background-color: transparent;
}

.ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title > .ant-menu-title-content {
    white-space: pre-line;
}

.ant-input-search
    > .ant-input-group
    > .ant-input-group-addon:last-child
    .ant-input-search-button:not(.ant-btn-primary) {
    background-color: transparent;
    box-shadow: none;
}

.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
    background-color: transparent;
}

// table
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
    @include fluid(font-size, 10px, 14px);
    @include fluid(line-height, 14px, 17px);
    background: transparent;
    border-bottom: none;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 700;
    color: $primary;

    &:first-child {
        // text-align: center;
    }
}

.ant-table-tbody > tr > td {
    font-weight: 500;
}

.ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before {
    content: none;
}

tr.ant-table-row.ant-table-row-level-0 {
    &:nth-child(2n) {
        background-color: #f5fcfe;
    }
}

.ant-table-tbody > tr > td {
    border-bottom: none;
}

//pagin
.ant-table-pagination > * {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ant-pagination-item {
    background: #bcbdc0;
    border-radius: 4px;
    border: none;
    transition: all 0.3s ease-in-out;

    a {
        color: #fff;
    }

    &:not(.ant-pagination-item-active):hover {
        background: $secondary;

        & a {
            color: #fff;
        }
    }

    &:hover {
        & a {
            color: #fff;
        }
    }
}

.ant-pagination-item-active {
    background: #1c4771;
}

// input select
.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: initial;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-search,
.ant-select-single .ant-select-selector .ant-select-selection-search {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: auto;
    background-color: #f5f6f6;
    padding: 10px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: auto;
    background-color: #f5f6f6;
    border: none;
    padding: 12.5px;

    &:after {
        line-height: initial;
    }
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    box-shadow: none;
}

//textarea

.ant-form-item-control-input-content textarea,
.ant-input-textarea-show-count > .ant-input {
    @include fluid(height, 100px, 152px);
    resize: none;
}

.ant-input-textarea.ant-input-textarea-show-count.ant-input-textarea-in-form-item {
    position: relative;
}

.ant-input-textarea-show-count.ant-input-textarea-in-form-item:after {
    font-size: 12px;
    line-height: 15px;
    color: #1c4771;
    position: absolute;
    right: 0;
    bottom: -15px;
}

// space
.ant-space-item {
    width: 100%;
}

//datepicker

.ant-picker {
    width: 100%;
    background-color: #f5f6f6;
    border: none;
    padding: 10px;

    &-focused,
    &:hover {
        box-shadow: none;
    }
}

.ant-select-dropdown {
    input {
        padding: 10px;
    }
}

//uplload
// .ant-upload.ant-upload-select-picture-card {
//   border: none;
//   border-radius: 5px;
//   background-color: #f5f6f6;
//   width: 166px;
//   height: 166px;
//   margin: 20px 0 0 0;
// }

.ant-upload.ant-upload-select-picture-card {
    border: 1px dashed $grey;
    border-radius: 5px;
}

.ant-upload-list-picture-card .ant-upload-list-item {
    border: 1px solid $grey;
    border-radius: 5px;
}

.ant-pagination {
    justify-content: center;
    align-items: center;
    display: flex;
}

// Если поедут где-то отступы лучше сделать обертки для блока и на них повесить отрицательный margin
// .ant-row {
//     margin-left: 0 !important;
//     margin-right: 0 !important;
// }

.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: none;
}

.ant-modal-content {
    border-radius: 10px;
}

.ant-modal-footer {
    padding: 30px;
}

.ant-modal-body {
    padding: 30px 30px 0;
}

.ant-checkbox {
    top: 0;
}

.ant-form-item-control-input {
    min-height: 0;
}

.ant-input-status-error {
    background: inherit;
    border-color: #ff7875;
}

.ant-form-item-has-error .ant-input,
.ant-form-item-has-error .ant-input:hover,
.ant-form-item-has-error .ant-select-selector,
.ant-form-item-has-error .ant-picker {
    box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
    border-right-width: 1px;
    border-color: #ff7875;
    background-color: #f5f6f6 !important;
}

.ant-form-item-has-error .quill {
    box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
    border-right-width: 1px;
    border-color: #ff7875;
}

.react-dadata__input {
    &:focus {
        border: none !important;
        box-shadow: none !important;
    }

    &:invalid {
        background: #fff;
        border-color: #ff4d4f;
    }
}

.ql-container.ql-snow {
    height: 220px;
}

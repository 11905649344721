@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.btns {
    display: block;
    &__item {
        margin: 0 !important;
        &:not(:last-child) {
            margin-bottom: 20px !important;
        }
    }
}

.modal {
    padding: 0;
    border-radius: 10px;
    width: 100% !important;
    @include fluid(max-width, 404px, 404px);
    box-shadow: 0 0 16px rgba(132, 132, 132, 0.25);
}

.report {
    margin: 0 -15px;
}

.item {
    display: flex;
    align-items: center;
    &__checkbox {
        margin: 0 15px 0 0;
    }
    &__label {
        @include fluid(font-size, 12px, 14px);
        line-height: 1.15;
        color: $dark-blue;
    }
}

.wrap {
    width: 900px !important;
    max-width: 900px !important;
}
.wrap{
  :global(.ant-form-item-label){
    label {
      font-weight: bold;
      font-size: 18px;
    };
  }
}
.footer{
  display: flex;
  gap: 30px;
  padding-bottom: 30px;
  > div {
    margin: 0;
    width: 100%;
  }
}

.modal {
  :global(.ant-modal-footer){
    display: flex;
  }
}
.assumption {
  margin-top: 30px;
  display: flex;
  &__label {
    padding-left: 20px;
    cursor: pointer;
    font-size: 16px;
  }
}
@import "_mixins";
@import "_fonts";
@import "_variables";
@import "_redesign-antd";

*,
*::before,
*::after {
    box-sizing: border-box;
}

html,
body {
    overflow-x: hidden;
}

body {
    font-family: "Inter" !important;
    font-weight: 400;
    @include fluid(font-size, 14px, 16px);
    color: $primary;
    background-color: $white;
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 0;
}

.container {
    @include container;
}

.lightCursorRound {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $secondary;
    border-radius: 50%;
    position: absolute;
    z-index: 8;
    transition: opacity 0.3s ease-in-out;
    cursor: pointer;

    img {
        position: relative;
        z-index: 2;
    }

    &::after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: $dark-blue !important;
        border-radius: 50%;
        z-index: 0;
        transform: scale(0);
        transition: transform 0.38s ease-in-out;
    }

    &:hover::after {
        transform: scale(1);
        z-index: 1;
    }

    &:hover {
        .animatedIcon {
            animation: animate 0.38s ease-in-out;
        }
    }
}

@keyframes animate {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

::-webkit-scrollbar {
    position: absolute;
    @include fluid(width, 6px, 8px);
    height: 8px;
    background-color: #efefef;
}

::-webkit-scrollbar-thumb {
    background-color: $dark-blue;
    -webkit-transition: background-color 0.38s ease-in-out;
    transition: background-color 0.38s ease-in-out;
    border-radius: 0px;
    // -webkit-box-shadow: inset 1px 1px 10px #f3faf7;
    // box-shadow: inset 1px 1px 10px #f3faf7;
}

.loader {
    @include fluid(font-size, 60px, 90px);
    color: $secondary;
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.buttonRed {
    background-color: $error;
    border-color: $error;
    color: $white;
    &:hover {
        background-color: $light-red;
        border-color: $light-red;
        color: $white;
    }
}

tr.drop-over-downward td {
    border-bottom: 2px dashed #1890ff;
}

tr.drop-over-upward td {
    border-top: 2px dashed #1890ff;
}
.formItem {
    display: flex;
    color: #231f20;
    font-size: 16px;
    gap: 10px;

    :global {
        .ant-form-item {
            margin-bottom: 0;
        }
    }

    &_label {
        margin-top: 15px;
    }

    &_wrapper {
        display: flex;
        flex-direction: column;
    }

    &_extra {
        display: block;
        margin-top: 9px;
    }
}

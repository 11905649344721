.actions {
  display: flex;
  align-items: center;
  & > *:not(:last-child) {
    margin-right: 30px;
  }
}
.searchBtn {
  margin-left: 0;
  :global {
    .ant-btn {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

.space {
  // какой-то баг, появляется margin справа
  //   width: 100%;

  &__between {
    justify-content: space-between;
  }

  &__evenly {
    justify-content: space-evenly;
    width: 100%;
  }

  &__start {
    justify-content: flex-start;
  }

  &__center {
    justify-content: center;
  }

  &__end {
    justify-content: flex-end;
  }

  &__fullHeight {
    height: 100%;
  }

  &__alignStart {
    align-items: flex-start;
  }

  &__alignCenter {
    align-items: center;
  }

  &__alignEnd {
    align-items: flex-end;
  }

  &__margBot {
    margin-bottom: 25px;
  }

  &__margTop {
    margin-top: 25px;
  }

  &__FormItemFullHeight {
    height: 100%;

    div{
      height: 100%
    }
  }
}

@import "../../assets/scss/mixins";
@import "../../assets/scss/variables";

.addButton {
    @include fluid(margin-bottom, 20px, 30px);
    width: fit-content;
    margin-left: auto;

    &_fullWidth {
        width: 100%;
        margin-left: 0;
    }

    &_noMargin {
        margin: 0;
    }

    &_primary {
        & > button:disabled {
            border: none;
            background-color: $light-blue;

            & span {
                color: #fff;
            }
        }

        & span svg {
            margin-right: 10px;

            path {
                fill: #fff;
            }
        }
    }

    :global {
        .ant-btn {
            box-shadow: none;
        }
    }
}

.red {
    color: $white;
    background-color: $error;
    border-color: $error;

    &:hover,
    &:focus {
        background-color: $red;
        border-color: $red;
    }
}

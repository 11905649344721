@import "../../assets/scss/variables";

.text {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    background-color: #f5f6f6;
    color: #1c4771;
    width: 100%;
}

.uploaderWrap {
    border-radius: 5px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    input {
        cursor: pointer;
        position: absolute;
        width: 100%;
        opacity: 0;
        left: 0;
        top: 0;
        bottom: 0;
        z-index: 2;
    }

    padding: 10px;
    color: rgba(0, 0, 0, 0.85);
    background-color: #f5f6f6;
    transition: all 0.3s;
}

@import "../../assets/scss/mixins";
@import "../../assets/scss/variables";
.label {
  font-family: "Inter", sans-serif;
  color: $black;

  &_form {
    font-weight: 600;
    @include fluid(font-size, 12px, 20px);
    @include fluid(line-height, 16px, 24px)
  }

  &_gray {
    color: $dark-gray;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
  }
}
@import "../../assets/scss/mixins";
@import "../../assets/scss/variables";

.logo {
  @include fluid(padding-left, 25px, 40px);
  @include fluid(padding-top, 20px, 30px);
  @include fluid(margin-bottom, 60px, 100px);

  &__login {
    position: absolute;
  }
}
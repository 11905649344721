.wrap{
  :global(.ant-form-item-label){
    label {
      font-weight: bold;
      font-size: 18px;
    };
  }
}
.footer{
  padding-bottom: 30px;
}
.button{
  margin: 0;
  width: 100%;
}
@import "../../assets/scss/variables";
@import "../../assets/scss/mixins";
.loader {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    color: $secondary;
    font-size: 100px;
  }
}

@import "../../assets/scss/variables";
@import "../../assets/scss/mixins";

.modalText {
    text-align: center;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;

    &__wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.modalTitle {
    text-align: center;
    font-family: "Inter";
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #000000;
    margin-bottom: 30px;
}

.btns {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 30px !important;

    &__item {
        font-size: 14px;
        line-height: 1.2;
        margin: 0 !important;
    }
}

.date {
    margin-bottom: 24px;

    &__title {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 15px;
    }

    &__label {
        :global {
            .ant-form-item-required span {
                font-size: 16px;
            }
        }
    }
}

.modal {
    width: 100% !important;
    padding: 30px;
    top: 0 !important;
    margin-top: 100px;

    .maxTitle {
        font-size: 29px;
        font-weight: 600;
        margin-bottom: 15px;
    }

    :global {
        .ant-modal-content {
            box-shadow: 0 0 32px rgba(132, 132, 132, 0.25);
            border-radius: 20px;
            overflow: hidden;
        }

        .ant-modal-body {
            padding: 30px;
        }

        .ant-modal-close-x {
            display: none;
        }

        .ant-modal-header {
            padding: 30px 30px 0;
            border-bottom: 0;
            text-align: center;

            > .ant-modal-title {
                font-size: 24px;
                font-weight: 600;
                color: #000000;
            }
        }

        .ant-modal-footer {
            padding: 0 30px 30px;
        }
    }

    &_delete {
        :global {
            .ant-modal-body {
                display: none;
            }

            .ant-modal-header {
                padding-top: 50px;
                font-size: 18px;
                line-height: 1.5;
                text-align: center;

                > .ant-modal-title {
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 1.5;
                }
            }

            .ant-modal-footer {
                padding-bottom: 50px;
                padding-top: 30px;
            }
        }
    }

    &_search {
        :global {
            .ant-modal-close-x {
                display: block;
            }
        }
    }

    .footer {
        width: 100%;
        max-width: 800px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 30px;
    }
}

:global {
    .ant-modal-wrap {
        //display: flex;
        //align-items: center;
        //justify-content: center;
    }
}

.block {
    margin-bottom: 24px;

    &:last-child {
        margin-bottom: 0;
    }
}

.subBlock {
    display: flex;
    gap: 30px;
}

.input {
    display: flex;
    align-items: flex-end;
    color: #231f20;
    font-size: 16px;
    gap: 10px;

    &_extra {
        display: block;
        margin-bottom: 33px;
    }

    :global {
        .ant-input {
            width: 130px;
            -webkit-border-radius: 4px;
            -moz-border-radius: 4px;
            border-radius: 4px;
        }
    }
}

@import "../../assets/scss/mixins";
@import "../../assets/scss/variables";
.tableHead {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  &_title {
    @include fluid(font-size, 18px, 24px);
    @include fluid(line-height, 24px, 32px);
    font-family: "Inter";
    font-weight: 600;
    color: #000000;
  }
}

@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.Modal {
    width: 750px;
    height: 500px;

    :global(.ant-modal-content) {
        width: 100%;
        height: 100%;
        display: flex;

        :global(.ant-modal-body) {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
        }
    }

    &_map {
        width: 100%;
        height: 100%;
        display: flex;
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        padding: 30px;
        background-color: $white;
        border-radius: 5px;
        box-shadow: 0 0 16px 0 rgba(132, 132, 132, 0.25);
        z-index: 99;
    }

    &_btns {
        display: flex;
        align-items: center;
        column-gap: 30px;
        margin-top: 30px;
        padding-bottom: 30px;
        width: 100%;
    }
}

[class*="ymaps-2"][class*="-ground-pane"] {
    filter: grayscale(1);
    -ms-filter: grayscale(1);
    -webkit-filter: grayscale(1);
    -moz-filter: grayscale(1);
    -o-filter: grayscale(1);
}

.map {
    position: relative;
    width: 750px;
    height: 360px;
}

.mapLoader {
    position: absolute;
    inset: 0;
    height: auto;
}

.tabs {
    &_operations {
        :global {
            .ant-tabs-nav-operations {
                display: none !important;
            }
        }
    }

    :global {
        .ant-tabs-nav {
            &:before {
                border: none;
            }
        }

        .ant-tabs-nav-list {
            gap: 10px;
        }

        .ant-tabs-tab {
            margin: 0;
            padding: 12px 24px;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            border-radius: 30px;
            background-color: #ccf0fc;
            color: #00b3ee;
            font-size: 18px;
            font-weight: 500;
            line-height: 1.333;

            transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;

            &:not(.ant-tabs-tab-active):hover {
                background-color: darken(#ccf0fc, 10%);
            }

            &-active {
                background-color: #00b3ee;

                .ant-tabs-tab-btn {
                    color: #fff;

                    &:focus,
                    &:active {
                        color: #fff;
                    }
                }
            }

            &-btn {
                &:focus,
                &:active {
                    color: inherit;
                }
            }

            &:focus,
            &:active {
                color: #fff;
            }
        }

        .ant-tabs-ink-bar {
            display: none;
        }
    }
}

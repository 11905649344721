@import "../../assets/scss/mixins";
@import "../../assets/scss/variables";

.header {
  @include fluid(padding-left, 310px, 335px);
  @include fluid(padding-right, 25px, 40px);
  @include fluid(padding-bottom, 20px, 30px);
  @include fluid(padding-top, 20px, 30px);
  width: 100%;
  background-color: #fff;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  z-index: 1;
  left: 0;
  right: 0;
  border-bottom: 4px solid #f5f6f6;

  &__title {
    @include fluid(font-size, 20px, 32px);
    @include fluid(line-height, 32px, 44px);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    color: #000000;
  }
  &__item {
    display: flex;
    align-items: center;
  }
  &__logText {
    @include fluid(font-size, 14px, 16px);
    @include fluid(line-height, 20px, 24px);
    margin-right: 30px;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    color: #231f20;
  }

  &__logUser {
    @include fluid(font-size, 14px, 16px);
    @include fluid(line-height, 20px, 24px);
    font-family: "Inter", sans-serif;
    font-weight: 600;
    color: #1c4771;
  }
}

@import "../../assets/scss/variables";

.wrap {
    width: 100%;
    position: relative;

    input {
        resize: none;
        border: none;
        width: 100%;
        background-color: #f5f6f6;
        outline: none;
        padding: 10px;
        border-radius: 2px;
    }

    &_invalid {
        input {
            border-color: #ff4d4f !important;
            box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2) !important;
        }
    }
}

.f {
    border-color: #ff4d4f !important;
    box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2) !important;
}
